<template>
  <b-form-group>
    <ul class="list-group dense">
      <li class="list-group-item">
        <k-collapsible-container>
          <template v-slot:title>
            Feliratok
          </template>
          <ul class="list-group dense mt-2">
            <li
              v-for="felirat in form.Nyelvesites"
              :key="felirat.Kulcs"
              class="list-group-item"
            >
              <k-inline-text-edit
                :value.sync="felirat.Szoveg"
                :text="felirat.Szoveg"
              >
              </k-inline-text-edit>
              <b-form-text>
                {{ felirat.Label }}
              </b-form-text>
            </li>
          </ul>
        </k-collapsible-container>
      </li>
    </ul>
  </b-form-group>
</template>

<script>
export default {
  name: 'pcf-feliratok',
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {},
  computed: {},
  watch: {},
  props: {
    form: {
      requied: true,
    },
  },
};
</script>
